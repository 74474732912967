import "./Toxicity.scss";
import { Canvas, useThree } from '@react-three/fiber'
import D20 from './Dice/D20';
import { Environment, OrbitControls, OrthographicCamera } from '@react-three/drei';
import { Physics, Triplet, useBox, usePlane } from "@react-three/cannon";
import { ShadowMaterial } from "three";
import { useEffect, useRef, useState } from "react";
import { createNewThread, fulfillToxicAction, queryToxicDM } from "../../stores/openai-store";
import ToxicityConversation from "./ToxicityConversation";
import { MessagesPage } from "openai/resources/beta/threads/messages";
import { Run } from "openai/resources/beta/threads/runs/runs";

export enum Abilities {
    Strength,
    Intelligence,
    Dexterity,
    Constitution,
    Charisma,
    Wisdom
};

export type Message = {
    role: 'assistant' | 'user';
    msg: string;
    isSystem?: boolean
};

const LLMMNenuComponent = () => {
    const [dice, setDice]                 = useState<object[]>([]);
    const [messages, setMessages]         = useState<Message[]>([]);
    const [isLoading, setIsLoading]       = useState<boolean>(false);
    const threadRef                       = useRef<string | null>();
    const [checkAbility, setCheckAbility] = useState<Abilities>(null);
    const [run, setRun]                   = useState<Run>();

    const removeDie = (index: number) => {
        setDice(dice.filter((die, i) => i !== index));
    };

    const receiveResponseFromToxic = (res: {messages: MessagesPage, action: any, run: Run}) => {
        setRun(res.run);
        setIsLoading(false);

        if (res.action) {
            if (res.action.name === "ability_check") {
                setCheckAbility(JSON.parse(res.action.arguments)?.stat);
            }
        } else if (res.messages.data) {
            const newMessages = res.messages.data.map(d => ({
                role: d.role,
                msg: d.content[0]["text"]?.value
            }));
            setMessages(newMessages);
        }
    };

    const submitPrompt = async (value: string, system: boolean = false) => {
        if (!system) {
            setMessages((prevMessages) => [{role: 'user', msg: value}, ...prevMessages]);
        }
        setIsLoading(true);

        if (!threadRef.current) {
            threadRef.current = await createNewThread().then(t => {
                return t.id;
            });
        }
        queryToxicDM(threadRef.current, value, system).then(receiveResponseFromToxic);
    };

    const submitCheck = async (value: number) => {
        setMessages((prevMessages) => [{role: 'assistant', msg: "Rolled a " + value, isSystem: true}, ...prevMessages]);
        setIsLoading(true);
        setCheckAbility(null);
        fulfillToxicAction(threadRef.current, run, [value]).then(receiveResponseFromToxic);
    };

    const rollDie = () => {
        setDice([{dice: "d20"}]);
    };

    return <div className="canvas-container">
        <ToxicityConversation
            messages={messages}
            abilityCheck={checkAbility}
            isLoading={isLoading}
            rollDie={rollDie}
            submitPrompt={submitPrompt}
            submitCheck={submitCheck}
        />

        <Canvas shadows className="canvas">
            <OrthographicCamera makeDefault position={[0, 10, 0]} rotation={[-Math.PI / 2, 0, 0]} zoom={100}/>
            
            <ambientLight intensity={Math.PI / 2} />
            <directionalLight
                position={[5, 5, 5]}
                intensity={1}
                castShadow
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-camera-far={15}
                shadow-camera-near={0.1}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            
            <Environment preset="studio"/>
            {/* <OrbitControls/> */}
            
            <Physics iterations={5}>
                {dice.map((die, index) =>
                    <D20
                        position={[0, 0, 0]}
                        key={index}
                        onRemove={() => removeDie(index)}
                    />
                )}
                <Ground/>
                <Walls/>
            </Physics>
        </Canvas>;
    </div>
};

const ShadowOnlyMaterial = new ShadowMaterial({opacity: 0.5});
const Ground = () => {
    const [ref] = usePlane(() => ({
        rotation: [-Math.PI / 2, 0, 0],
        position: [0, 0, 0]
    }));

    return <object3D ref={ref}>
        <mesh receiveShadow>
            <planeGeometry args={[100, 100]} />
            <primitive object={ShadowOnlyMaterial} attach="material"/>
        </mesh>
    </object3D>
};

const Wall = ({ position, rotation}) => {
    const dimensions: Triplet = [1, 20, 20];
    const [ref] = useBox(() => ({
        position,
        rotation,
        args: dimensions,
        type: 'Static'
    }));

    return (<object3D ref={ref}>
        <mesh>
            <boxGeometry args={dimensions} />
            {/* <meshBasicMaterial color="hotpink" opacity={1} /> */}
        </mesh>
    </object3D>);
}

const Walls = () => {
    const { camera, size } = useThree();
    const aspect = size.width / size.height;
    const viewWidth = 10 / camera.zoom;
    const viewHeight = (viewWidth / aspect) -0.6;

    return (
        <>
            <Wall position={[viewWidth, 5, 0]} rotation={[0, 0, 0]} />
            <Wall position={[-viewWidth, 5, 0]} rotation={[0, 0, 0]} />
            <Wall position={[0, 5, -viewHeight]} rotation={[0, Math.PI / 2, 0]} />
            <Wall position={[0, 5, viewHeight]} rotation={[0, Math.PI / 2, 0]} />
        </>
    );
};

export default LLMMNenuComponent;