import { OpenAI } from "openai";
import { apiCall } from "./store-util";
import dayjs from "dayjs";

const openai = new OpenAI({
    organization: "org-rON0ehkoSmFqPXWcLUauDput",
    apiKey: process.env.REACT_APP_OPENAI_APIKEY,
    dangerouslyAllowBrowser: true
});

let allMessages;

export const createNewThread = async () => {
    return await openai.beta.threads.create();
}

const handleRun = async (threadId, run) => {
    let action = null;

    while (run.status === "queued" || run.status === "in_progress") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        run = await openai.beta.threads.runs.retrieve(threadId, run.id);
    }

    if (run.status === "requires_action") {
         run?.required_action?.submit_tool_outputs?.tool_calls.map(tool => {
            if (tool?.function?.name === "ability_check") {
                action = tool?.function;
            }
        });
    } else if (run.status === "completed") {
        allMessages = await openai.beta.threads.messages.list(threadId);
    }

    return {
        messages: allMessages,
        action: action,
        run: run
    };
};

export const queryToxicDM = async (threadId, query, system = false) => {
    await openai.beta.threads.messages.create(threadId, {
        role: system ? 'assistant' : 'user',
        content: query
    });

    const run = await openai.beta.threads.runs.create(threadId, { assistant_id: "asst_CHXcvzafeiHEFgpLAFuofkQL" });

    return await handleRun(threadId, run);
}

export const fulfillToxicAction = async (threadId, run, outputs) => {
    const toolOutputs = run?.required_action?.submit_tool_outputs?.tool_calls.map((tool, i) => {
        if (tool?.function?.name === "ability_check") {
            return {
                tool_call_id: tool?.id,
                output: outputs[i],
            };
        }
    });

    return await openai.beta.threads.runs.submitToolOutputsAndPoll(threadId, run.id, { tool_outputs: toolOutputs })
        .then(async (res) => {
            return await handleRun(threadId, res);
        });
};

export const generateNewWisdom = () => {
    return openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            {role: "system", content: "Write a short message of daily wisdom to be displayed on the front page of a website. Make it less than 100 characters long. No quotes, any extra pronunciation, or additional text."}
        ],
        max_tokens: 100,
        n: 1,
        stream: false
    }).then((r) => {
        return r?.choices[0]?.message?.content || "Error";
    });
}

export const getDailyWisdom = () => {
    return apiCall("get-daily-wisdom").then((response: any) => {
        const res = JSON.parse(response)[0];
        if (dayjs().isSame(dayjs(res.last_updated), 'day')) {
            return res.wisdom;
        }

        return generateNewWisdom().then(msg => {
            if (msg !== "Error") {
                apiCall("set-daily-wisdom", {
                    wisdom: msg
                });
            }
            return msg;
        });
    });
}