import { useEffect, useState } from "react";
import Card from "../../common/CardComponent";
import { getDailyWisdom } from "../../stores/openai-store";

const HomeComponent = () => {
    const [dailyWisdom, setDailyWisdom] = useState<string>("Loading...");

    useEffect(() => {
        getDailyWisdom().then(setDailyWisdom);
    }, []);

    return <div className="home-container">
        <h1 className="name-header">Riley Davis</h1>
        <div className="daily-wisdom">
        <Card classes="daily-wisdom">Daily Chat GPT Wisdom: {dailyWisdom}</Card>
        </div>
    </div>
}

export default HomeComponent;