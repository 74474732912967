import { useEffect, useRef, useState } from "react";
import { Abilities, Message } from "./ToxicityMenu";
import {ReactComponent as RightArrow} from "./RightArrow.svg";
import {ReactComponent as D20Icon} from "./D20Icon.svg";

import dungeonsanddragons from "./Games/dungeonsanddragons.jpg";
import dungeonsandzombies from "./Games/dungeonsandzombies.jpg"
import cyberpunkred from "./Games/cyberpunkred.jpg";
import trenchcrusade from "./Games/trenchcrusade.jpg";

type ToxicityConversationProps = {
    messages: Message[];
    abilityCheck: Abilities;
    isLoading: boolean;
    rollDie: () => void;
    submitPrompt: (val: string, sys?: boolean) => void;
    submitCheck: (val: number) => void;
};

type Game = {
    title: string,
    description: string,
    background: any,
    init: string,
    classes: Class[]
};

type Class = {
    title: string,
}

const games: Game[] = [
    {
        title: "Dungeons and Dragons",
        description: "The classic roleplaying experience, ",
        background: dungeonsanddragons,
        init: "The player chose to play in the default Dungeons and Dragons setting, they deserve a versatile and free world filled with all the cliche fantasy excitement that the game has to offer.",
        classes: [
            {
                title: "Barbarian"
            },
            {
                title: "Ranger"
            },
            {
                title: "Rogue"
            },
            {
                title: "Bard"
            },
            {
                title: "Wizard"
            }
        ]
    },
    {
        title: "D&Z",
        description: "Survive in the modern day zombie apocalypse",
        background: dungeonsandzombies,
        init: "The game setting will be in D&Z, which is a modern-day zombie apocalypse game that uses standard 5e rules",
        classes: [
            {
                title: "Burgular"
            },
            {
                title: "Doctor"
            },
            {
                title: "Farmer"
            },
            {
                title: "Scientist"
            }
        ]
    }, {
        title: "Trench Crusade",
        description: "A bleak alternate history where the forces of heaven and hell clash in the WW1 trenches of 1914",
        background: trenchcrusade,
        init: "The setting of this campaign is the grimdark world of Trench Crusade. During the first crusade in the year 1099, a rebellious group of templars encountered a demonic artifact in the holy land of Jerusalem and opened a gate to Hell itself. It is now the year 1914 of our Lord, trenches and artillery shells scathe the landscape of No Man's Land.",
        classes: [
            {
                title: "War Prophet"
            },
            {
                title: "Trench Pilgrim"
            },
            {
                title: "Heretic Priest"
            }
        ]
    }, {
        title: "Cyberpunk Red",
        description: "Live in the year 2044, where corporations and gangs use cyber augmentations to take control of the streets of Night City",
        background: cyberpunkred,
        init: "This RPG will take the scene of Cyberpunk Red, set in the year 2044 where corporate warfare and criminal activity is bolstered by cyberaugmentation technology and netrunners",
        classes: [
            {
                title: "Netrunner"
            },
            {
                title: "Nomad"
            },
            {
                title: "Corpo Agent"
            },
            {
                title: "Streetkid"
            }
        ]
    }
];

const ToxicityConversation = ({ messages, abilityCheck, isLoading, rollDie, submitPrompt, submitCheck }: ToxicityConversationProps) => {
    const textarea                    = useRef(null);
    const checkinput                  = useRef(null);
    const [checkValue, setCheckValue] = useState<number>(10);

    useEffect(() => {
        if (textarea.current) {
            textarea.current.focus();
        }
        const keydown = (e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (document.activeElement === textarea?.current) {
                    handleSend();
                } else if (document.activeElement === checkinput?.current) {
                    handleCheck();
                }
            }
        };

        document.addEventListener("keydown", keydown);

        return () => {
            document.removeEventListener("keydown", keydown);
        };
    }, []);

    useEffect(() => {
        if (!isLoading && !abilityCheck) {
            textarea.current.focus();
        }
    }, [isLoading]);

    const handleSend = () => {
        submitPrompt(textarea?.current?.value);
        textarea.current.value = "";
    };

    const handleCheck = () => {
        submitCheck(checkinput.current?.value);
        checkinput.current.value = 10;
    };

    const startGame = (game) => {
        submitPrompt(game.init, true);
    };

    return <div className="toxicity-conversation-wrapper">
        <div className="conversation-messages">
            {messages.length === 0 && !isLoading && <div className="game-selection-container">
                <h2>Choose a setting</h2>
                <div className="game-selection">
                    {games.map((game, index) => <div className="game" key={index} onClick={() => startGame(game)}>
                        <img src={game.background}/>
                        <div className="game-details">
                            <h3>{game.title}</h3>
                            <p>{game.description}</p>
                        </div>
                    </div>)}
                </div>
            </div>}
            {abilityCheck && <div className="ability-check">
                <button onClick={rollDie}>
                    <D20Icon width={40} height={40}/>
                </button>
                <input
                    type="text"
                    onChange={(v) => {
                        let value = parseInt(v.target.value);
                        if (isNaN(value)) {
                            return;
                        }
                        if (value > 20) {
                            value = 20;
                        } else if (value < 1) {
                            value = 1;
                        }
                        setCheckValue(value)
                    }}
                    value={checkValue}
                    ref={checkinput}/>
                <button disabled={isLoading} onClick={handleCheck}>
                    <RightArrow width={40} height={40}/>
                </button>
            </div>}
            {messages.map((msg, i) => <div className={`message ${msg.role} ${msg.isSystem && "system"}`} key={i}>{msg.msg}</div>)}
        </div>
        {isLoading && <div className="loading-icon">
            <div className="spinner"></div>
        </div>}
        <div className="conversation-footer">
            <textarea rows={4} disabled={isLoading || !!abilityCheck} placeholder="Type out your query for the campaign" ref={textarea}></textarea>
            <button disabled={isLoading || !!abilityCheck} onClick={handleSend}>
                <RightArrow width={40} height={40}/>
            </button>
        </div>
    </div>
};

export default ToxicityConversation;