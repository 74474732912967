/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Don42 (https://sketchfab.com/Don42)
license: CC-BY-SA-4.0 (http://creativecommons.org/licenses/by-sa/4.0/)
source: https://sketchfab.com/3d-models/d20-dice-w20-wurfel-3d-model-free-3b44541db43e4e979efec290ec706cbc
title: D20 Dice (W20 Würfel) 3D model [FREE]
*/

import React, { useEffect, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import d20Model from "./Models/d20_dice_w20_wurfel_3d_model_free.glb";
import { Quaternion, Vector3 } from 'three';
import { useConvexPolyhedron, useCannon } from '@react-three/cannon';

export default function Model(props) {
  const { nodes, materials } = useGLTF(d20Model);

  const faceMapping = {
    4: 14,
    14: 12,
    20: 9
  };

  const vertices = useMemo(() => {
    const v = [];
    const position = nodes.Icosphere_Main_0.geometry.attributes.position;
    for (let i = 0; i < position.count; i++) {
      v.push([position.getX(i), position.getY(i), position.getZ(i)]);
    }
    return v;
  }, [nodes]);

  const faces = useMemo(() => {
    const index = nodes.Icosphere_Main_0.geometry.index;
    const f = [];
    for (let i = 0; i < index.count; i += 3) {
      f.push([index.getX(i), index.getX(i + 1), index.getX(i + 2)]);
    }
    return f;
  }, [nodes]);

  const [ref, api] = useConvexPolyhedron(() => ({
    mass: 1,
    args: [vertices, faces],
    position: [-5, 2, -2]
  }));

  useEffect(() => {
    if (api) {
      api.velocity.set(Math.random() * 15, 0, Math.random() * 15);
      api?.angularVelocity.set(Math.random() * 10, Math.random() * 10, Math.random() * 10);

      const unsubVelocity = api.angularVelocity.subscribe((velocity) => {
        const [vx, vy, vz] = velocity;
  
        if (Math.abs(vx) < 0.01 && Math.abs(vy) < 0.01 && Math.abs(vz) < 0.01) {
            determineTopFace();
            unsubVelocity();
        }
      });
      return () => {
        unsubVelocity();
      }
    }

  }, [api]);

  const determineTopFace = () => {
    const unsub = api.quaternion.subscribe((quaternionArray) => {
      const quaternion = new Quaternion(...quaternionArray);
      const up = new Vector3(0, 1, 0).applyQuaternion(quaternion);

      let maxDot = -Infinity;
      let topFaceIndex = -1;

      faces.forEach((face, index) => {
        const centroid = new Vector3();
        face.forEach((vertexIndex) => {
          centroid.add(new Vector3(...vertices[vertexIndex]));
        });
        centroid.divideScalar(face.length).normalize();

        const dot = centroid.dot(up);
        if (dot > maxDot) {
          maxDot = dot;
          topFaceIndex = index;
        }
      });

    });
    setTimeout(() => {
      props.onRemove();
      unsub();
    }, 5000);
  };

  return (
    <group ref={ref} {...props} dispose={null}>
      <group rotation={[0, 0, 0]}>
        <group rotation={[0, 0, 0]}>
          <group rotation={[0, 0, 0]} scale={[1, 1, 1]}>
            <mesh geometry={nodes.Icosphere_Main_0.geometry} material={materials.Main} castShadow />
            <mesh geometry={nodes.Icosphere_Letters_0.geometry} material={materials.Letters} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(d20Model)
